import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import {
  CTASection,
  CardsBlockSection,
  CompatibleDevices,
  ContentBlockSection,
  FAQSection,
  FeaturesAccordion,
  Reviews,
} from '@containers';
import { CTAFeatures, Card, Layout, SEO, Shape, Text } from '@components';
import { Box } from '@core';
import { PAGE_DATA } from '@shared/constants/pages/download';
import { useServersQuery } from '@data/servers';

const DownloadPage = ({ location, data }) => {
  const {
    metaData,
    headerCta,
    footerCta,
    compatibleDevices,
    perksBefore,
    surfOnlineSmoothly,
    perksAfter,
    features,
    reviews,
    faq,
    moneyBackGuarantee,
  } = PAGE_DATA({ images: data.allFile.edges });

  const { t } = useTranslation();

  const { locationCount } = useServersQuery();

  return (
    <Layout
      location={location}
      navProps={{ initialBackgroundColor: { _: '#BFE2FF', lg: 'transparent' } }}
      wrapperProps={{ backgroundColor: 'unset' }}
    >
      <SEO {...metaData(t)} />
      <CTASection
        data={headerCta}
        descriptionComponent={CTAFeatures}
        sectionProps={{
          wrapperProps: { pt: 0, pb: { lg: 112 } },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
          pt: 80,
        }}
        contentProps={{
          pt: 120,
          pb: 40,
        }}
      />
      <CompatibleDevices
        {...compatibleDevices(t)}
        desktopCard={Card.IconLink}
        sectionProps={{
          wrapperProps: { pb: { _: 80, lg: 120 } },
          textWrapperProps: { pb: { _: 80, md: 100, lg: 140 } },
        }}
      />
      <CardsBlockSection
        {...perksBefore({ t, locationCount })}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { py: { md: 64 } } }}
      />
      <ContentBlockSection
        {...surfOnlineSmoothly(t)}
        shape={Shape.Blob2}
        shapeProps={{
          left: { _: '-30%', sm: 0, md: '40%' },
          top: 0,
        }}
        wrapperProps={{ pb: { _: 16, md: 0 }, py: { md: 8, lg: 0 } }}
      />
      <CardsBlockSection
        {...perksAfter({ t })}
        itemComponent={Card.Feature}
        itemsOnDesktop={2}
        sectionProps={{ wrapperProps: { pb: { _: 64, md: 88 } } }}
      />
      <Box mt={{ _: 24, md: 32 }} mb={{ _: 24, md: 66 }}>
        <FeaturesAccordion {...features({ t })} />
      </Box>
      <Reviews {...reviews(t)} />
      <ContentBlockSection
        {...moneyBackGuarantee(t)}
        shape={Shape.Blob4}
        shapeProps={{ top: { _: '5%', md: 0 } }}
        wrapperProps={{ pb: { _: 24, md: 0 } }}
        sectionProps={{ wrapperProps: { py: { _: 64, md: 88 } } }}
      />
      <FAQSection {...faq({ locationCount })} />
      <CTASection
        data={footerCta}
        showAvailablePlatforms
        titleComponent={Text.Heading2}
        sectionProps={{
          wrapperProps: { pb: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
        }}
        contentProps={{
          pt: 160,
          pb: 80,
        }}
      />
    </Layout>
  );
};

export default DownloadPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "download", "plans", "checkout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "download/download-header-image.png"
            "download/download-header-image-tablet.png"
            "download/download-header-image-mobile.png"
            "product-page/safebrowse.png"
            "product-page/unlimited-devices.png"
            "product-page/best-location-detector.png"
            "product-page/best-in-class-encryption.png"
            "product-page/data-breach-monitor.png"
            "product-page/surf-online-smoothly.png"
            "product-page/30-day-guarantee.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
